
<template>
  <div>
    <!-- <div class="breadcrumb"> -->
    <!-- </div> -->
    <div class="content">
      <el-tabs type="border-card"
               v-model="tabsVal"
               @tab-click="tabsHandleClick">
        <!--欠费订单修改区域-->
        <el-tab-pane v-if='authority.tabs["debtUpate"]'
                     label="欠费订单修改">
          <div class="options">
            <el-form ref="form"
                     :model="formInline"
                     label-width="100px">
              <!--操作 第一行-->
              <el-form-item label="停车场名称:">
                <my-component ref="parkInput"
                              :operationId='formInline.operationId'
                              @valueChange='completeValue'
                              slaveRelations='0,1'
                              :parkNameValue='parkNameValue'>
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')"
                            prop="plateNumber">
                <el-autocomplete ref='plateNumber'
                                 size="11"
                                 valueKey='plateNumber'
                                 class="inline-input"
                                 v-model="formInline.plateNumber"
                                 :fetch-suggestions="querySearchAsync"
                                 placeholder="车牌号"
                                 :trigger-on-focus="false"
                                 @select="handleSelect"></el-autocomplete>
              </el-form-item>
              <br />
              <!--操作 第二行-->
              <div class="options-bottom">
                <el-form-item label="入出场类型:">
                  <el-select v-model.trim="formInline.entryExitType"
                             filterable
                             size="10">
                    <el-option :label="item.desc"
                               :value="item.code"
                               :key="item.code"
                               v-for="item in ExitTypeList"></el-option>
                  </el-select>
                </el-form-item>
                <div class="block">
                  <el-form-item label="时间段:">
                    <timeRangePick @timeChange="timeChange" ref="timeRangePicker" :defalutDate="defalutDate"/>
                    <!-- <el-date-picker v-model="date1"
                                    type="datetime"
                                    placeholder="选择日期"
                                    @change="setStartTime"> </el-date-picker> 至
                    <el-date-picker v-model="date2"
                                    type="datetime"
                                    placeholder="选择日期"
                                    @change="setEndTime"> </el-date-picker> -->

                    <el-button type="primary"
                               @click="pageNum=1;searchData()"
                               class="inquire">{{ $t('button.search') }}</el-button>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <!--列表-->
          <div class="tableWrapper">
            <el-table border
                      
                      v-loading="loading"
                      :data="tableData"
                      style="width: 100%">
              <el-table-column align='center'
                               :prop="item.prop"
                               :label="item.label"
                               :width="item.width"
                               v-for="item in tableCols"
                               :key="item.prop"
                               :formatter="item.formatter"></el-table-column>
              <el-table-column align='center'
                               label="操作"
                               width="80"
                               v-if='authority.tabs["debtUpate"].button.deal'>
                <template slot-scope="scope">
                  <el-button type="text"
                             size="small"><span v-show="scope.row.deal===1"
                                                @click="handleClick(scope.row)">未处理</span><span v-show="scope.row.deal===2"
                                                                                                style="color:#ccc">已处理</span></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagerWrapper">
            <div class="block">
              <el-pagination v-if="total != 0"
                             @current-change="handleCurrentChange"
                             :current-page="pageNum"
                             :page-size="pageSize"
                             layout="total, prev, pager, next, jumper"
                             :total="total">
              </el-pagination>
            </div>
          </div>
          <el-dialog title="处理"
                     :visible.sync="dialogFormVisible">
            <el-form :model="form">
              <el-form-item label="矫正出场时间"
                            :label-width="formLabelWidth">
                <el-date-picker v-model="form.correctExitTime"
                                type="datetime"
                                @change="LabelWidthChange"
                                placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="矫正欠费金额"
                            :label-width="formLabelWidth">
                <el-input v-model="form.correctMoney"
                          placeholder="选择输入矫正欠费金额"></el-input>
              </el-form-item>
              <el-form-item label="原因"
                            :label-width="formLabelWidth">
                <el-select v-model.trim="form.updateDebtReason"
                           placeholder="请选择活动区域">
                  <el-option label="PDA出场延迟"
                             value="1"></el-option>
                  <el-option label="设备漏抓拍"
                             value="2"></el-option>
                  <el-option label="其他"
                             value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.remarks')"
                            :label-width="formLabelWidth"
                            v-show="form.updateDebtReason == 3">
                <el-input type="textarea"
                          v-model="form.desc"
                          maxlength="100"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer"
                 class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary"
                         @click="submit">提交</el-button>
            </div>
          </el-dialog>
        </el-tab-pane>
        <!--修改记录查询区域-->
        <el-tab-pane v-if='authority.tabs["updateList"]'
                     label="修改记录查询">
          <amendantRecord :authority='authority.tabs["updateList"]' />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker"
import {
  setIndex,
  dateFormat
} from '../../common/js/public.js'
import amendantRecord from "./amendantRecord.vue"
import myComponent from '@/components/autocomplete/myautoComponent'
export default {
  name: 'oweToModify',
  components: {
    amendantRecord,
    myComponent,
    timeRangePick
  },
  data () {
    return {
      defalutDate: [],
      tabsVal: '0',
      authority: this.$route.meta.authority,
      parkNameValue: '',
      form: {
        correctExitTime: '',
        correctMoney: '',
        updateDebtReason: "1",
        desc: ''
      },
      formLabelWidth: '150px',
      date3: '',
      loading: false,
      pageNum: 1,
      record: '',
      dialogFormVisible: false,
      total: 0,
      pageSize: 15,
      tableData: [],
      ExitTypeList: [],
      parkList: [],
      formInline: {
        parkId: '',
        carId: '',
        parkNumber: '',
        startTime: '',
        endTime: '',
        entryExitType: 1
      },
      tableCols: [{
        prop: 'index',
        label: '序号',
        width: '70'
      }, {
        prop: 'operationName',
        label: this.$t("list.Merchant_Name"),
        width: ''
      }, {
        prop: 'areaName',
        label: this.$t("list.region"),
        width: ''
      }, {
        prop: 'parkName',
        label: '停车场名称',
        width: '120'
      }, {
        prop: 'plateNumber',
        label: '车牌号',
        width: '160'
      }, {
        prop: 'entryTime',
        label: '入场时间',
        width: '100'
      }, {
        prop: 'exitTime',
        label: '出场时间',
        width: '120'
      }, {
        prop: 'money',
        label: '欠费金额',
        width: '100',
        formatter: (row) => {
          if (row.money) {
            return Number((row.money) / 100).toFixed(2)
          } else {
            return '0.00'
          }
        }
      }],
      chargeVO: ''
    }
  },
  watch: {
    form: {
      handler (curVal, oldVal) {
        this.$nextTick(_ => {
          if (curVal.correctMoney.length == 1) {
            this.form.correctMoney = curVal.correctMoney.replace(/[^\d{1}]/g, '');
          } else {
            this.form.correctMoney = curVal.correctMoney.replace(/[^\d{1,}.\d{1,}|\d{1,}]/g, '');
          }
        })
      },
      deep: true
    }
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
        : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
        .getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    setStartTime (value) {
      this.formInline.startTime = dateFormat(value)
    },
    setEndTime (value) {
      this.formInline.endTime = dateFormat(value)
    },
    tabsHandleClick (tab) {
      if (this.tabsVal != '1') {
        this.searchData();
      } else {
        tab.$children[0].searchData()
      }
    },
    LabelWidthChange (value) {
      if (!value) {
        return
      }
      let time = dateFormat(value, 'yyyy-MM-dd HH:mm:ss');
      if (new Date(time) < new Date(this.record.entryTime) || new Date(time) > new Date(this.record.exitTime)) {
        this.$alert('矫正出场时间必须大于等于入场时间或小于等于出场时间！', this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        });
        this.form.correctExitTime = '';
        return
      }
      this.$axios.get('/acb/2.0/feeScene/charge/calc', {
        data: {
          carId: this.record.carId,
          parkId: this.record.parkId,
          entryTime: this.record.entryTime,
          exitTime: dateFormat(value, 'yyyy-MM-dd HH:mm:ss'),
          parkRecordId: this.record.parkRecordId
        }
      }).then(res => {
        this.chargeVO = res.value;
        if (res.value.money && res.value.money > 0) {
          this.form.correctMoney = (res.value.money / 100).toFixed(2)
        } else {
          this.form.correctMoney = "0";
        }
      })
    },
    submit () {
      if (!/^\d*(\.\d{1,2})?$/.test(this.form.correctMoney)) {
        this.$alert('只能输入数字，小数点后只能保留两位')
        this.form.correctMoney = '';
        return
      }
      if (!this.form.correctExitTime || !this.form.correctMoney || !this.form.updateDebtReason) {
        this.$alert('填写完整信息');
        return
      }
      let time = dateFormat(this.form.correctExitTime, 'yyyy-MM-dd HH:mm:ss');
      if (this.form.correctMoney * 100 >= this.record.money || this.form.correctMoney < 0) {
        this.$alert('矫正后金额必须大于等于0或小于欠费金额!', this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        });
        return;
      }
      if (this.form.updateDebtReason == 3 && this.form.desc == '') {
        this.$alert('请输入其他备注原因！')
        return
      }
      this.$axios.post('/acb/2.0/updateDebtOrder/create', {
        data: {
          parkRecordId: this.record.parkRecordId,
          plateNumber: this.record.plateNumber,
          parkTime: this.record.parkTime,
          parkType: this.record.parkType || '',
          correctMoney: (this.form.correctMoney * 100).toFixed(0),
          correctExitTime: time,
          updateDebtReason: this.form.updateDebtReason,
          desc: this.form.desc,
          chargeVO: JSON.stringify(this.chargeVO)
        }
      }).then(res => {
        if (res.state == 0) {
          this.dialogFormVisible = false;
          // this.ExitTypeList = res.value;
          this.searchData();
        }
      })
    },
    completeValue (item) {
      this.formInline.parkId = item.parkId;
      this.formInline.parkName = item.parkName;
    },
    handleCurrentChange (val) {
      this.pageNum = val;
      this.searchData();
    },
    handleClick (row) {
      this.dialogFormVisible = true;
      this.record = row;
      this.form.correctMoney = '';
      this.form.correctExitTime = '';
      this.form.desc = '';
      this.form.updateDebtReason = '1';
    },
    // 出入场类型列表
    getExitType () {
      let opt = {
        url: '/acb/2.0/parkRecord/entryExitType/list',
        method: 'get',
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert('获取出入场类型失败', this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        }
      };
      this.$request(opt);
    },
    handleSelect (item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData()
    },
    querySearchAsync (queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([])
        this.formInline.carId = '';
        return
      }
      let opt = {
        method: 'get',
        url: '/acb/2.0/parkRecord/plateNumber/list',
        data: {
          part: queryString,
          size: 100
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach(v => {
              v.value = v.plateNumber
            })
            cb(res.value);
          }
        }
      }
      this.$request(opt);
    },
    showLog () {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true
        }
      } else {
        this.$alert('日期不能为空', this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        });
        return false
      }
    },
    // 搜索
    searchData () {
      if (this.formInline.parkId == '') {
        this.formInline.parkId = '';
        this.$refs.parkInput.setShowVal('')
      }
      if (this.formInline.plateNumber == '') {
        this.formInline.carId = ''
      }
      if (this.formInline.plateNumber && this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = '';
        this.formInline.carId = '';
      }
      let flag = this.showLog();
      if (!flag) return
      let opt = {
        method: 'get',
        url: '/acb/2.0/debtOrder/list',
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          entryExitType: this.formInline.entryExitType,
          parkId: this.formInline.parkId,
          plateNumber: this.formInline.plateNumber,
          carId: this.formInline.carId
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
          this.loading = false;
        }
      };
      if (flag) {
        this.loading = true;
        this.$refs.parkInput.setShowVal(this.formInline.parkName)
        this.$request(opt);
      }
    }
  },
  created() {
    this.defalutDate = [this.dataTimeRest() + ' 00:00:00', this.dataTimeRest() + ' 23:59:59']
  },
  mounted () {
    this.getExitType();
    // this.searchData();
  }
}
</script>
<style media="screen">
.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-form
  padding-top 25px
  box-sizing border-box
.content
  height 50px
  margin-top 18px
.el-form-item
  display inline-block
.options-bottom
  display flex
.inquire
  margin-left 10px
</style>
